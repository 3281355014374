import {
  ILiveChannel,
  IParsedChannels,
  IRawLiveChannel,
  IStreamUrls,
} from '@canalplus/oneplayer-types';

import { logger } from '../logger';
import { isNullOrUndefined } from '../object';
import removeDiacritics from '../string/removeDiacritics';
import { template } from '../template';

/**
 * TODO: This should be done backend side.
 * On certain devices (Samsung TV) we want to show the users the nonAuthorizedChannel (groupType = 2)
 * But this groupType currently contains the channel Canal+ for prospective clients (epgId = 601)
 * Which would result in a duplicate Canal+ channel for premium users (301 and 601)
 * So we want to filter this channel here
 * @param channel IRawLiveChannel
 * @param groupType string
 * @returns boolean
 */
function isDuplicateCanalChannelInUnauthorizedGroup(
  channel: IRawLiveChannel,
  groupType: string,
): boolean {
  return (
    (channel.EpgId === '601' || channel.EpgId === '301') && groupType === '2'
  );
}

/**
 * Transform/Parse from a IRawLiveChannel interface to a ILiveChannel interface.
 * Make a data structure more usable inside the OnePlayer.
 * This concern data we receive from the InitLiveTV call.
 * We also make two distinct data structure between the channel that are authorized
 * to user to play and those that are not authorized.
 * For example, for a non authenticated user, we will receive all channels but most of them
 * will be non authorized since he is not a premium user.
 * @param channels array of channels
 * @param groupType group type
 * @param RMUToken rmu token
 * @returns A sanitized/parsed LiveChannels
 */
function parseChannels(
  channels: IRawLiveChannel[],
  groupType: string,
  RMUToken?: string,
): IParsedChannels {
  const formattedUrl = (url: string): string =>
    RMUToken ? template(url, { RMUToken }) : url;

  return channels.reduce<IParsedChannels>(
    (acc, channel) => {
      // Skip the channels that dont have the mandatory fields.
      if (
        isNullOrUndefined(channel.Name) ||
        isNullOrUndefined(channel.EpgId) ||
        isDuplicateCanalChannelInUnauthorizedGroup(channel, groupType)
      ) {
        return acc;
      }

      // It's ok for channel.WSXUrl to be undefined
      // however depending on the platform it might be an issue
      if (isNullOrUndefined(channel.WSXUrl)) {
        logger.warn(
          `OnePlayer > parseChannels > WSXUrl missing for channel: ${channel.Name}, epgId: ${channel.EpgId}`,
        );
      }

      let isChannelNbAnArray = false;
      let channelNumbering: number[][] = [[]];
      let number: number;
      if (Array.isArray(channel.Nb)) {
        isChannelNbAnArray = true;
        channelNumbering = channel.Nb.map((channelNumbers) =>
          channelNumbers.map((channelNumber) => parseInt(channelNumber, 10)),
        );
        [[number]] = channelNumbering;
      } else {
        number = parseInt(channel.Nb.toString(), 10);
      }

      const streamUrls: IStreamUrls | null = channel.AssetUrls
        ? {
            ...(channel.AssetUrls.NoRight && {
              NoRight: formattedUrl(channel.AssetUrls.NoRight),
            }),
            ...(channel.AssetUrls.Dvb && {
              Dvb: formattedUrl(channel.AssetUrls.Dvb),
            }),
            ...(channel.AssetUrls.DvbUhd && {
              DvbUhd: formattedUrl(channel.AssetUrls.DvbUhd),
            }),
            ...(channel.AssetUrls.OttUrls && {
              Ott: {
                Default: formattedUrl(channel.AssetUrls.OttUrls.Default),
                Sdr: formattedUrl(channel.AssetUrls.OttUrls.Sdr),
                Hdr: formattedUrl(channel.AssetUrls.OttUrls.Hdr),
                SdrLL: formattedUrl(channel.AssetUrls.OttUrls.SdrLL),
                HdrLL: formattedUrl(channel.AssetUrls.OttUrls.HdrLL),
              },
            }),
            ...(channel.AssetUrls.IptvUrls && {
              Iptv: {
                Sd: formattedUrl(channel.AssetUrls.IptvUrls.Sd),
                Hd: formattedUrl(channel.AssetUrls.IptvUrls.Hd),
                Hdp: formattedUrl(channel.AssetUrls.IptvUrls.Hdp),
                Uhd: formattedUrl(channel.AssetUrls.IptvUrls.Uhd),
              },
            }),
            ...(channel.AssetUrls.Default && {
              Default: formattedUrl(channel.AssetUrls.Default),
            }),
            ...(channel.AssetUrls.Sdr && {
              Sdr: formattedUrl(channel.AssetUrls.Sdr),
            }),
            ...(channel.AssetUrls.Hdr && {
              Hdr: formattedUrl(channel.AssetUrls.Hdr),
            }),
            ...(channel.AssetUrls.SdrLL && {
              SdrLL: formattedUrl(channel.AssetUrls.SdrLL),
            }),
            ...(channel.AssetUrls.HdrLL && {
              HdrLL: formattedUrl(channel.AssetUrls.HdrLL),
            }),
          }
        : null;

      const refinedChannelProps: ILiveChannel = {
        name: channel.Name,
        normalizedName: removeDiacritics(channel.Name),
        groupType: parseInt(groupType, 10),
        lineUp: channel.LineUp,
        number,
        epgId: parseInt(channel.EpgId, 10),
        thematic: channel.Them,
        logoUrlRaw: channel.LogoUrl,
        logoUrl: template(channel.LogoUrl, { resolutionXY: '360x270' }),
        logoUrlLight: template(channel.LogoUrl.replace(/FB/g, 'FN'), {
          resolutionXY: '120x90',
        }),
        isChannelAuthorized:
          Boolean(channel.AccessRights.NoAccess === 'false') || false,
        isStartOverAuthorized: Boolean(channel.DVR === 'true') || false,
        isCat5Authorized:
          Boolean(channel.AccessRights.Cat5Access === 'true') || false,
        isFullCat5: Boolean(channel.FullCat5 === 'true') || false,
        isCastable: Boolean(channel.IsCastable === 'true') || false,
        isAdAntiSkip: Boolean(channel?.IsAdAntiSkip === 'true') || false,
        noSeekDurationForAdAtBeginning: channel?.NoSeekDurationForAdAtBeginning
          ? parseInt(channel.NoSeekDurationForAdAtBeginning, 10)
          : null,
        noSeekDurationForAdAfterBeginning:
          channel?.NoSeekDurationForAdAfterBeginning
            ? parseInt(channel.NoSeekDurationForAdAfterBeginning, 10)
            : null,
        hasCat5: Boolean(channel.HasCat5 === 'true') || false,
        hasEncryptedPrograms: Boolean(channel.Hybrid === 'true') || false,
        WSXUrl: isNullOrUndefined(channel.WSXUrl)
          ? null
          : formattedUrl(channel.WSXUrl),
        WSXUrlLL: streamUrls?.SdrLL || null,
        ...(isChannelNbAnArray && { channelNumbering }),
        isAutostart: Boolean(channel.IsAutostart === 'true') || false,
        ...(channel.ExtServiceUrl && { extServiceURL: channel.ExtServiceUrl }),
        isDVR: Boolean(channel.DVR === 'true') || false,
        ...(streamUrls && { streamUrls }),
      };
      if (refinedChannelProps.isChannelAuthorized) {
        acc.authorizedChannels.push(refinedChannelProps);
        acc.allChannels.push(refinedChannelProps);
        return acc;
      }
      acc.allChannels.push(refinedChannelProps);
      return acc;
    },
    { authorizedChannels: [], allChannels: [] },
  );
}

export default parseChannels;
