import { IInitLiveTVRawData, ILiveTime } from '@canalplus/oneplayer-types';
import LocalStorage from './LocalStorage';

const TTL = 300_000; // 300 seconds expiry time

interface ISavedData extends IInitLiveTVRawData {
  expiryTime: number;
  passToken: string;
  liveTime: ILiveTime;
}

class DataStorage extends LocalStorage {
  private static dataInstance: DataStorage | undefined;

  public static getInstance(): DataStorage {
    if (!DataStorage.dataInstance) {
      DataStorage.dataInstance = new DataStorage();
    }

    return DataStorage.dataInstance;
  }

  constructor() {
    super('data');
  }

  async saveInitLiveTVData(
    data: IInitLiveTVRawData,
    passToken: string,
    initLiveTvVersion: string,
    liveTime: ILiveTime,
  ): Promise<void> {
    const now = new Date();

    const savedData: ISavedData = {
      ...data,
      // Add expiryTime
      expiryTime: now.getTime() + TTL,
      passToken,
      liveTime,
    };

    await this.setItem(`initLiveTVData:${initLiveTvVersion}`, savedData);
  }

  async getSavedInitLiveTVData(
    passToken: string,
    initLiveTvVersion: string,
    bypassExpiration = false,
  ): Promise<Omit<ISavedData, 'expiryTime' | 'passToken'> | null> {
    const savedData: ISavedData = await this.getItem(
      `initLiveTVData:${initLiveTvVersion}`,
    );

    if (!savedData) {
      return null;
    }

    const now = new Date();
    const isExpired = now.getTime() > savedData.expiryTime;
    const isCurrentUser = savedData.passToken === passToken;

    if (!bypassExpiration && (isExpired || !isCurrentUser)) {
      return null;
    }

    // Remove expiryTime and passToken from savedData
    const { expiryTime, passToken: _pass, ...rest } = savedData;

    return rest;
  }
}

export const dataStorage = DataStorage.getInstance();

export default DataStorage;
