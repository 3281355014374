import { Lang, OfferLocation, OfferZone, Platform, PlatformGroup, PlatformGroupMap } from '@canalplus/sdk-core';
import { RenderSource } from '../../../constants/renderSource';
import { IApplicationState } from '../../types/Application-type';
import { ApplicationActionTypes, ApplicationTypeKeys } from '../actions/Application-actions';

const defaultInitialState: IApplicationState = {
  requestId: '',
  isFetching: false,
  renderSource: RenderSource.SERVER,
  onePlayerIsSupported: false,
  zoneInfo: { offerLocation: OfferLocation.fr, lang: 'fr', offerZone: OfferZone.cpfra },
  platform: Platform.Web,
  platformGroup: PlatformGroup.Web,
  settings: {
    hostname: '',
    showAdultDisclaimer: true,
    appDownloadToGoDetected: false,
    searchUrl: 'http://testUrl.com/{cmsToken}/{searchKey}',
    userAgent: {
      isMobile: {
        isAny: true,
        isAndroid: false,
        isIOS: false,
        isWindows: false,
      },
      isBrowser: {
        isSafari: false,
      },
      isDesktop: {
        isAny: false,
        isMacOs: false,
        isWindows: false,
      },
    },
  },
  isClearCacheCalled: false,
  token: '',
  navigation: [],
  footer: {
    tree: [],
  },
  headerLevelPageList: [],
  firstLevelPageList: [],
  whoIsWatchingUserSettings: { disabled: false, inSession: false },
  shortVideoIds: [],
};

/**
 * Application state reducer factory
 * @param {object} initialState - Current state
 * @returns {function} - Reducer
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
const applicationReducer = (state = defaultInitialState, action: ApplicationActionTypes): IApplicationState => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case ApplicationTypeKeys.APPLICATION_RENDER_SOURCE:
      return {
        ...state,
        renderSource: action.renderSource,
      };
    case ApplicationTypeKeys.APPLICATION_LOAD_PLAYER_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isPlayerScriptLoaded: action.isPlayerScriptLoaded,
        },
      };
    case ApplicationTypeKeys.APPLICATION_LOAD_D2G_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          isD2GScriptLoaded: action.isD2GScriptLoaded,
        },
      };
    case ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_SUCCESS: {
      const zoneInfo = state.zoneInfo;
      const userMenu = action.payload.authenticate.userMenu;
      const userMenuExtended = action.payload.authenticate.userMenuExtended;
      const userMenuLanguages = action.payload.authenticate.userMenuLanguages;
      const tree = action.payload.authenticate.footer;
      const navigation = action.payload.authenticate.arborescence || [];
      const logo = action.payload.authenticate?.logo || {};
      const startupNotifications = action.payload.authenticate.startupNotifications || [];

      return {
        ...state,
        footer: { ...state.footer, tree },
        isFetching: false,
        galaxy: action.payload.authenticate.galaxy,
        logo,
        navigation,
        settings: { ...state.settings, ...action.payload.authenticate.settings },
        socialMedia: action.payload.authenticate.socialMedia,
        token: action.payload.authenticate.token,
        userMenu: userMenu?.map((menu) => ({
          ...menu,
          picto: menu.picto?.toLowerCase(),
        })),
        startupNotifications,
        userMenuExtended,
        userMenuLanguages,
        zoneInfo,
      };
    }
    case ApplicationTypeKeys.APPLICATION_GET_USER_AGENT:
      return {
        ...state,
        settings: {
          ...state.settings,
          userAgent: action.userAgent,
        },
      };
    case ApplicationTypeKeys.APPLICATION_SET_HOSTNAME:
      return {
        ...state,
        settings: { ...state.settings, hostname: action.hostname },
      };
    case ApplicationTypeKeys.APPLICATION_OVERRIDE_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.settings },
      };
    case ApplicationTypeKeys.APPLICATION_SET_ZONE_INFO:
      return {
        ...state,
        zoneInfo: {
          ...state.zoneInfo,
          offerLocation: action.offerLocation,
          appKey: action.appKey,
          portailId: action.portailId,
          offerZone: action.offerZone,
          lang: action.lang,
        },
      };
    case ApplicationTypeKeys.APPLICATION_SET_LOCALE_FROM_HODOR: {
      let { locale } = action;

      locale = locale.length <= 2 ? locale : locale.split('-')[0];
      return {
        ...state,
        zoneInfo: {
          ...state.zoneInfo,
          lang: locale as Lang,
        },
      };
    }
    case ApplicationTypeKeys.HEADER_DISPLAY: {
      return {
        ...state,
        header: {
          isHidden: action.isHeaderHidden,
        },
      };
    }
    case ApplicationTypeKeys.FOOTER_DISPLAY: {
      return {
        ...state,
        footer: {
          isHidden: action.isFooterHidden,
        },
      };
    }
    case ApplicationTypeKeys.GDPR_DISPLAY: {
      return {
        ...state,
        GDPR: {
          shouldShowGDPR: action.shouldShowGDPR,
        },
      };
    }

    case ApplicationTypeKeys.SET_D2G_QUALITY:
      return {
        ...state,
        settings: {
          ...state.settings,
          settingQualityD2G: action.payload.quality,
        },
      };
    case ApplicationTypeKeys.SET_IN_CONTAINER:
      return {
        ...state,
        header: {
          ...state.header,
          isInContainer: action.payload,
        },
      };

    case ApplicationTypeKeys.APPLICATION_SET_CONTEXT_INSTANTIATION: {
      return {
        ...state,
        platform: action.platform,
        platformGroup: PlatformGroupMap[action.platform],
      };
    }

    case ApplicationTypeKeys.SET_HEADER_LEVEL_PAGE_LIST: {
      return { ...state, headerLevelPageList: action.payload.pageList, firstLevelPageList: action.payload.pageList };
    }

    case ApplicationTypeKeys.SET_FIRST_LEVEL_PAGE_LIST: {
      return {
        ...state,
        firstLevelPageList: [
          ...state.firstLevelPageList,
          ...action.payload.pageList.filter((pathname) => !state.firstLevelPageList.includes(pathname)),
        ],
      };
    }

    case ApplicationTypeKeys.APPLICATION_SET_RESIZE:
      return {
        ...state,
        resize: action.payload.resize,
      };

    case ApplicationTypeKeys.APPLICATION_SET_HODOR_SDK_CONFIG:
      return {
        ...state,
        hodorSdkConfig: action.payload.hodorSdkConfig,
      };

    case ApplicationTypeKeys.APPLICATION_SET_CONFIGURATION_JSON:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload.configurationJson.globalSettings },
      };

    case ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_SESSION:
      return {
        ...state,
        whoIsWatchingUserSettings: {
          ...state.whoIsWatchingUserSettings,
          inSession: !!action.payload,
        },
      };

    case ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_DISABLED:
      return {
        ...state,
        whoIsWatchingUserSettings: {
          ...state.whoIsWatchingUserSettings,
          disabled: !!action.payload,
        },
      };
    case ApplicationTypeKeys.RESET_WHO_IS_WATCHING_USER_SETTINGS:
      return {
        ...state,
        whoIsWatchingUserSettings: {
          ...state.whoIsWatchingUserSettings,
          inSession: false,
          disabled: false,
        },
      };

    case ApplicationTypeKeys.APPLICATION_SET_SHORT_VIDEO_IDS:
      return {
        ...state,
        shortVideoIds: action.payload.shortVideoIds,
      };

    case ApplicationTypeKeys.APPLICATION_SET_INIT_TRACKING:
      return {
        ...state,
        initTracking: action.payload.initTracking,
      };
    case ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING_CONTEXT:
      return {
        ...state,
        startupNotificationsTrackingContext: action.payload,
      };
    case ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING:
      return {
        ...state,
        startupNotificationsTracking: action.payload,
      };

    default:
      return state;
  }
};

export default applicationReducer;
