import { isClientSide, PROFILE } from '@canalplus/mycanal-commons';
import { createSelector } from '@reduxjs/toolkit';
import { extractEpgIDsFromAccessibleChannels } from '../../helpers/liveTV/liveTV-helper';
import { getCurrentProfile } from '../../services/profiles/profileStorageService';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import { AccessibleChannel, UserState } from '../../store/slices/user-type';
import { IState } from '../../store/types/State-type';

const userSelector = (state: IState): UserState => state?.user;

export const analyticsIdSelector = createSelector(userSelector, (user) => user?.analyticsId);

export const anonymousIdSelector = createSelector(userSelector, (user) => user?.anonymousId);

export const waitForPassFinishSelector = createSelector(userSelector, (user) => user?.waitForPassFinish || false);

export const cgaNumberSelector = createSelector(userSelector, (user) => user?.cgaNumber);

export const authenticatedSelector = createSelector(userSelector, (user) => user?.authenticated || false);

export const isPartnerSelector = createSelector(userSelector, (user) => user?.isPartner || false);

export const profileSelector = createSelector(userSelector, (user) => user?.profile);

export const isFetchingUserPaymentMeansSelector = createSelector(userSelector, (user) => user?.isFetching || false);

export const profilesSelector = createSelector(userSelector, (user) =>
  user?.profiles.filter((profile) => profile.type === PROFILE)
);

export const hasProfilesLengthSelector = createSelector(userSelector, (user) => (user?.profiles || []).length > 0);

export const genderSelector = createSelector(profileSelector, (profile) => profile?.gender);

export const isKidsProfileSelector = createSelector(profileSelector, (profile) => {
  const storedIsKidsProfile = profile?.isKidsProfile;

  if (storedIsKidsProfile !== undefined) {
    return !!profile?.isKidsProfile;
  }

  if (isClientSide()) {
    return !!getCurrentProfile()?.isKidsProfile;
  }

  return false;
});

export const profileIdSelector = createSelector(profileSelector, (profile) => {
  const storedProfileId = profile?.profileId;

  if (storedProfileId !== undefined) {
    return storedProfileId;
  }

  if (isClientSide()) {
    return getCurrentProfile()?.profileId;
  }

  return undefined;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const allowSuppressionSelector = (id?: number) =>
  createSelector(profileIdSelector, (currentId) => currentId !== id && id !== 0);

export const firstnameSelector = createSelector(profileSelector, (profile) => profile?.firstname);

export const nicknameSelector = createSelector(profileSelector, (profile) => profile?.nickname);

export const profileNameSelector = createSelector(profileSelector, (profile) => profile?.displayName);

export const profileImgSelector = createSelector(profileSelector, (profile) => profile?.URLImage);

export const lastnameSelector = createSelector(profileSelector, (profile) => profile?.lastname);

export const emailSelector = createSelector(profileSelector, (profile) => profile?.email);

export const profilesUpdateDateSelector = createSelector(
  profileSelector,
  (profile) => profile?.profilesUpdateDate || ''
);

export const personalInformationSelector = createSelector(
  [firstnameSelector, lastnameSelector, nicknameSelector, emailSelector],
  (firstname, lastname, nickname, email) => ({
    firstname,
    lastname,
    nickname,
    email,
  })
);

export const settingsSelector = createSelector(userSelector, (user) => user?.settings);

export const areAnimationsAutoplaySelector = createSelector(
  settingsSelector,
  (settings) => settings?.areAnimationsAutoplay ?? true
);

export const areAnimationsMutedSelector = createSelector(
  settingsSelector,
  displayTVModeSelector,
  (settings, isTvDevice) => settings?.areAnimationsMuted ?? !isTvDevice
);

export const isTrailerTemporarilyUnmutedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isTrailerTemporarilyUnmuted ?? false
);

export const isShortVideoListTemporarilyUnmutedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isShortVideoListTemporarilyUnmuted ?? false
);

export const hasUserDataCollectedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.hasUserDataCollected ?? true
);

export const hasAnalyticsCollectedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.hasAnalyticsCollected || false
);

export const hasTrackingPubCollectedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.hasTrackingPubCollected || false
);

export const hasAnonymousTrackingSelector = createSelector(
  settingsSelector,
  (settings) => settings?.hasAnonymousTracking ?? true
);

export const isNextEpisodeAutoplaySelector = createSelector(
  settingsSelector,
  (settings) => settings?.isNextEpisodeAutoplay ?? true
);

export const isTrailerAutoplaySelector = createSelector(
  settingsSelector,
  (settings) => settings?.isTrailerAutoplay ?? true
);

export const isTrailerMutedSelector = createSelector(
  settingsSelector,
  displayTVModeSelector,
  (settings, isTvDevice) => settings?.isTrailerMuted ?? !isTvDevice
);

export const showMoodExplanationSelector = createSelector(
  settingsSelector,
  (settings) => settings?.showMoodExplanation ?? true
);

export const imageQualityPercentageBySettingsSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentage || 80
);

export const isPushMoodSelector = createSelector(settingsSelector, (settings) => settings?.isPushMood ?? true);

export const isHDRSelector = createSelector(settingsSelector, (settings) => settings?.isHDR ?? undefined);

export const isLowLatencySelector = createSelector(settingsSelector, (settings) => settings?.isLowLatency ?? false);

export const abTestingPopulationSelector = createSelector(userSelector, (user) => user?.abTestingPopulation);

export const abTestingPercentageSelector = createSelector(userSelector, (user) => user?.abTestingPercentage);

export const microEligibilitySelector = createSelector(userSelector, (user) => user?.microEligibility);

export const macroEligibilitySelector = createSelector(userSelector, (user) => user?.macroEligibility);

export const epgidOTTSelector = createSelector(userSelector, (user) => user?.epgidOTT);

export const epgidSelector = createSelector(userSelector, (user) => user?.epgid);

export const idpTokenSelector = createSelector(userSelector, (user) => user?.idpToken || '');

export const passIdSelector = createSelector(userSelector, (user) => user?.passId || '');

export const passTokenSelector = createSelector(userSelector, (user) => user?.passToken || '');

export const adTokenSelector = createSelector(userSelector, (user) => user?.adToken || '');

export const subscriberIdSelector = createSelector(userSelector, (user) => user?.subscriberId);

export const accountIdSelector = createSelector(userSelector, (user) => user?.accountId);

export const userProfileSelector = createSelector(userSelector, (user) => user?.profile);

export const passTokenCreationDateSelector = createSelector(userSelector, (user) => user?.passTokenCreationDate);

export const targetedAdsSelector = createSelector(userSelector, (user) => user?.targetedAds);

export const notificationSelector = createSelector(userSelector, (user) => user?.notification);

export const segTypeSelector = createSelector(notificationSelector, (notification) => notification?.segType);

export const externalServicesSelector = createSelector(userSelector, (user) => user?.externalServices || []);

export const displayProfileMenuSelector = createSelector(userSelector, (user) => user?.displayProfileMenu || false);

export const accessibleChannelsSelector = createSelector(userSelector, (user) => user?.accessibleChannels || []);

export const areAccessibleChannelsLoadedSelector = createSelector(
  userSelector,
  (user) => user?.areAccessibleChannelsLoaded ?? false
);

export const accessibleEpgIDsSelector = createSelector(accessibleChannelsSelector, (channels) =>
  extractEpgIDsFromAccessibleChannels(channels)
);

export const isStartOverAuthorizedByEpgIDSelector = createSelector(
  accessibleChannelsSelector,
  (channels: AccessibleChannel[]): { [key: number]: boolean } =>
    channels.reduce((acc, channel: AccessibleChannel) => {
      acc[channel.epgID] = channel.isStartOverAuthorized;
      return acc;
    }, {})
);
