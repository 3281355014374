import { getCookie, setCookie } from '@canalplus/mycanal-commons';
import { getDeviceIdCookieValue, type PassUserData } from '@canalplus/sdk-pass';
import { CookieKey } from '../../constants/cookie';

export const setDeviceIdCookie = (passUserData: PassUserData): void => {
  const currentDeviceId = getCookie(CookieKey.DeviceId);

  if (currentDeviceId) {
    const [, currentIsAuthenticatedStatus] = currentDeviceId.split(':');
    const currentIsAuthenticatedStatusBool = currentIsAuthenticatedStatus === '1';
    const isAuthenticatedChanged = passUserData.isAuthenticated !== currentIsAuthenticatedStatusBool;

    if (!isAuthenticatedChanged) {
      return;
    }
  }

  setCookie(
    CookieKey.DeviceId,
    getDeviceIdCookieValue({
      previousDeviceId: currentDeviceId,
      isAuthenticated: passUserData.isAuthenticated,
    }),
    {
      expires: 1 / 48, // 30 minutes
    }
  );
};
