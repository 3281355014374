import { IButtonStrate, ISectionsStrate, offerZonesList } from '@canalplus/mycanal-sdk';
import { Platform, PlatformGroup } from '@canalplus/sdk-core';
import { ContentType, HodorSdkConfig } from '@canalplus/sdk-hodor';
import { ApiV2NavigationNotification } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { ApiV2InitDataLayer } from '@dce-front/hodor-types/api/v2/me/init/definitions';
import { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';
import { createSelector } from '@reduxjs/toolkit';
import { IPublic } from '../../../config/application/types';
import { RenderSource } from '../../constants/renderSource';
import { isFirstLevelPage, OVERLAY_APPLICATION_THEMES } from '../../helpers/application/application-helper';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { pathnameSelector } from '../../store/slices/routing-selectors';
import {
  analyticsIdSelector,
  anonymousIdSelector,
  hasAnalyticsCollectedSelector,
  hasAnonymousTrackingSelector,
  hasUserDataCollectedSelector,
  macroEligibilitySelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from '../../store/slices/user-selectors';
import type { UserState } from '../../store/slices/user-type';
import type { IApplicationState } from '../../store/types/Application-type';
import type { IState } from '../../store/types/State-type';
import { SettingQualityD2G } from '../../templates/DownloadToGo/types';

const applicationSelector = (state: IState): IApplicationState => state?.application;

export const userSelector = (state: IState): UserState => state?.user;

export const settingsSelector = createSelector(applicationSelector, (application) => application?.settings);

export const zoneInfoSelector = createSelector(applicationSelector, (application) => application?.zoneInfo);

export const tokenCMSSelector = createSelector(applicationSelector, (application) => application?.token);

export const userMenuSelector = createSelector(applicationSelector, (application) => application?.userMenu || []);

export const userMenuLengthSelector = createSelector(userMenuSelector, (userMenu) => userMenu.length > 0);

export const userAgentSelector = createSelector(settingsSelector, (settings) => settings?.userAgent);

export const subscribeCallToActionsSelector = createSelector(
  settingsSelector,
  (settings) => settings?.subscribeCallToActions
);

export const subscribeButtonSelector = createSelector(settingsSelector, (settings) => settings?.subscribeButton);

export const subscribeUrlSelector = createSelector(settingsSelector, (settings) => settings?.subscribeUrl);

export const subscribeUrlHeaderSelector = createSelector(settingsSelector, (settings) => settings?.subscribeUrlHeader);

export const subscribeUrlLiveSelector = createSelector(settingsSelector, (settings) => settings?.subscribeUrlLive);

export const trackingLibraryUrlSelector = createSelector(settingsSelector, (settings) => settings?.trackingLibraryUrl);

/** @public */
export const upsellCallToActionsSelector = createSelector(
  settingsSelector,
  (settings) => settings?.upsellCallToActions
);

export const searchUrlSelector = createSelector(settingsSelector, (settings) => settings?.searchUrl);

export const notificationsUrlSelector = createSelector(settingsSelector, (settings) => settings?.notificationsUrl);

export const imageQualityPercentageSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentage ?? 80
);

export const imageQualityPercentageLowSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentageLow ?? 0
);

export const imageQualityPercentageHighSelector = createSelector(
  settingsSelector,
  (settings) => settings?.imageQualityPercentageHigh ?? 100
);

export const maxImageRatioSelector = createSelector(settingsSelector, (settings) => settings?.maxImageRatio);

export const maxPosterRatioSelector = createSelector(settingsSelector, (settings) => settings?.maxPosterRatio);

/** @public */
export const d2gQualitySelector = createSelector(settingsSelector, (settings) => settings?.settingQualityD2G);

export const isMobileSelector = createSelector(userAgentSelector, (userAgent) =>
  typeof userAgent?.isMobile?.isAny === 'boolean' ? userAgent.isMobile.isAny : true
);

export const isAndroidSelector = createSelector(userAgentSelector, (userAgent) => !!userAgent?.isMobile?.isAndroid);

export const isSafariSelector = createSelector(userAgentSelector, (userAgent) => !!userAgent?.isBrowser?.isSafari);

export const isMacOsSelector = createSelector(userAgentSelector, (userAgent) => !!userAgent?.isDesktop?.isMacOs);

export const isDesktop = createSelector(userAgentSelector, (userAgent) => userAgent?.isDesktop || false);

export const userStatusSelector = createSelector(settingsSelector, (settings) => settings?.userStatus);

export const didomiNoticeIdSelector = createSelector(settingsSelector, (settings) => settings?.noticeId);

export const isProspectSelector = createSelector(
  userStatusSelector,
  (userStatus) => userStatus === getPublicConfig().user_status.prospect
);

export const isSubscriberSelector = createSelector(
  userStatusSelector,
  (userStatus) => userStatus === getPublicConfig().user_status.abonne
);

export const isPlayerScriptLoadedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isPlayerScriptLoaded || false
);

export const isD2GScriptLoadedSelector = createSelector(
  settingsSelector,
  (settings) => settings?.isD2GScriptLoaded || false
);

export const hostnameSelector = createSelector(settingsSelector, (settings) => settings?.hostname || '');

export const appKeySelector = createSelector(zoneInfoSelector, (locale) => locale?.appKey || 'mycanal');

const getDefaultLocale = (): IPublic['defaultLocale'] => getPublicConfig().defaultLocale;

export const offerZoneSelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.offerZone || offerZonesList[getDefaultLocale().offerLocation]
);

export const portailIdSelector = createSelector(zoneInfoSelector, (locale) => locale?.portailId || '');

export const langKeySelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.lang || getDefaultLocale()?.langKey
);

export const offerLocationSelector = createSelector(
  zoneInfoSelector,
  (locale) => locale?.offerLocation || getDefaultLocale()?.offerLocation
);

export const fullLocaleSelector = createSelector(
  [langKeySelector, offerLocationSelector],
  (lang, locale) => `${lang}-${locale}`
);

export const adultSubscriptionSelector = createSelector(settingsSelector, (settings) => settings?.isAdult || false);

export const navItemsSelector = createSelector(settingsSelector, (settings) => settings?.navItems);

/** @public */
export const applicationHeaderSelector = createSelector(applicationSelector, (application) => application?.header);

/** @public */
export const isInContainerSelector = createSelector(
  applicationHeaderSelector,
  (header) => header?.isInContainer || 'true'
);

export const applicationFooterSelector = createSelector(
  applicationSelector,
  (application) => application?.footer || {}
);

export const applicationFooterTreeSelector = createSelector(applicationFooterSelector, (footer) => footer?.tree || []);

/** @public */
export const applicationGDPRSelector = createSelector(applicationSelector, (application) => application?.GDPR || {});

export const applicationDescriptionSelector = createSelector(
  settingsSelector,
  (settings) => settings?.description || 'No description'
);

export const downloadManagerUrlSelector = createSelector(settingsSelector, (settings) => settings?.downloadManagerUrl);

export const externalServiceActivationUrlSelector = (state: IState, tvPackID: string): any =>
  createSelector(settingsSelector, (settings) => settings && settings[`${tvPackID}ActivationUrl`])(state);

export const socialMediaSelector = createSelector(applicationSelector, (application) => application?.socialMedia || []);

export const downloadMacOsAppUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.downloadMacOsAppUrl
);

export const hodorSdkConfigSelector = createSelector(
  applicationSelector,
  analyticsIdSelector,
  anonymousIdSelector,
  hasAnalyticsCollectedSelector,
  hasAnonymousTrackingSelector,
  passTokenSelector,
  profileIdSelector,
  macroEligibilitySelector,
  microEligibilitySelector,
  offerZoneSelector,
  offerLocationSelector,
  tokenCMSSelector,
  (
    application,
    analyticsId,
    anonymousId,
    isAnalyticsEnabled,
    isAnonymousAnalyticsEnabled,
    passToken,
    profileId,
    macros,
    micros,
    offerZone,
    offerLocation,
    cmsToken
  ): HodorSdkConfig | undefined =>
    application?.hodorSdkConfig
      ? ({
          ...application.hodorSdkConfig,
          analyticsId,
          anonymousId,
          isAnalyticsEnabled,
          isAnonymousAnalyticsEnabled,
          macros,
          micros,
          offerLocation,
          offerZone,
          passToken,
          profileId: profileId?.toString() || '0',
          cmsToken,
        } satisfies HodorSdkConfig)
      : undefined
);

/**
 * Super selectors
 */

export const requestIdSelector = createSelector(applicationSelector, (application) => application?.requestId);

export const navigationSelector = createSelector(applicationSelector, (application) => application?.navigation || []);

export const hasNavigationSelector = createSelector(navigationSelector, (navigation) =>
  navigation ? navigation.length > 0 : false
);

export const isHeaderHiddenSelector = createSelector(applicationSelector, (application) =>
  typeof application?.header?.isHidden === 'boolean' ? application.header.isHidden : false
);

export const isFooterHiddenSelector = createSelector(applicationFooterSelector, (footer) => footer?.isHidden || false);

export const shouldShowGDPRSelector = createSelector(applicationGDPRSelector, (GDPR) => GDPR?.shouldShowGDPR || false);
/**
 * Feature toggles
 */
export const featAvatarV2Selector = createSelector(
  settingsSelector,
  (settings) => settings?.featAvatarV2 ?? true // by default true (need for the whoIsWatching screen)
);

export const featIdentityV5Selector = createSelector(settingsSelector, (settings) => settings?.featIdentityV5 || false);

export const featShortVideoPlayerSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featShortVideoPlayer || false
);

export const featDisplayHeaderMenuSelector = createSelector(settingsSelector, (settings) =>
  typeof settings?.featDisplayHeaderMenu === 'boolean' ? settings.featDisplayHeaderMenu : true
);

export const featUpcomingHodorStickerSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featUpcomingHodorSticker || false
);

export const getFeatureToggleProfile = createSelector(
  settingsSelector,
  hasUserDataCollectedSelector,
  (settings, hasUserDataCollected) => (settings?.featProfile || false) && hasUserDataCollected
);

export const platformSelector = createSelector(
  applicationSelector,
  (application) => application?.platform || Platform.Web
);

export const platformGroupSelector = createSelector(
  applicationSelector,
  (application) => application?.platformGroup || PlatformGroup.Web
);

export const getFeatureToggleKidsProfiles = createSelector(
  settingsSelector,
  platformGroupSelector,
  (settings, platformGroup) => settings?.featKidsProfiles ?? platformGroup !== PlatformGroup.Orange // by default disable on orange but enable on other devices
);

export const renderSourceSelector = createSelector(
  applicationSelector,
  (state) => state?.renderSource ?? RenderSource.CLIENT
);

export const isClientRenderSourceSelector = createSelector(
  renderSourceSelector,
  (renderSource) => renderSource === RenderSource.CLIENT
);

export const getFeatureTogglePerso = createSelector(settingsSelector, (settings) => settings?.featPerso || false);

export const getFeatureToggleUserCentricConsent = createSelector(
  settingsSelector,
  (settings) => settings?.featUserCentricConsentMode || false
);

export const getFeatureTogglePushMood = createSelector(settingsSelector, (settings) => settings?.featPushMood || false);

export const getFeatureToggleTVoD = createSelector(settingsSelector, (settings) => settings?.featTVoD || false);

export const getFeatureToggleTvodUrbaQRCode = createSelector(
  settingsSelector,
  (settings) => settings?.featTvodUrbaQRCode || false
);

export const getFeatureToggleCB1S = createSelector(settingsSelector, (settings) => settings?.featCB1S || false);

export const getFeatureToggleNotifications = createSelector(
  settingsSelector,
  (settings) => settings?.featNotifications || false
);

export const getFeatureTogglePositionSeconds = createSelector(
  settingsSelector,
  (settings) => settings?.featPositionSeconds || false
);

export const getFeatureToggleAds = createSelector(settingsSelector, (settings) => settings?.featPlayerAds || false);

export const getFeatureToggleNewrelic = createSelector(settingsSelector, (settings) => settings?.featNewrelic || false);

export const getFeatureToggleBuyNKeep = createSelector(settingsSelector, (settings) => settings?.featBuyNKeep || false);

export const getFeatureToggleD2G = createSelector(settingsSelector, (settings) => settings?.featD2G || false);

export const getFeatureToggleDidomi = createSelector(settingsSelector, (settings) => settings?.featDidomi || false);

export const getFeatureToggleHdr = createSelector(settingsSelector, (settings) => settings?.featHdr || false);

export const getFeatureToggleLowLatency = createSelector(
  settingsSelector,
  (settings) => settings?.featLowLatency || false
);

export const getFeatureToggleTrailerPreview = createSelector(
  settingsSelector,
  (settings) => settings?.featTrailerPreview || false
);

export const getFeatureTogglePromotionCover = createSelector(
  settingsSelector,
  (settings) => settings?.featPromotionCover || false
);

export const getFeatureTogglePromotionBanner = createSelector(
  settingsSelector,
  (settings) => settings?.featPromotionBanner || false
);

export const getFeatureToggleDetailLight = createSelector(
  settingsSelector,
  (settings) => settings?.featXPSport || false
);

export const getFeatureToggleDetailV5Sport = createSelector(
  settingsSelector,
  (settings) => settings?.featDetailV5Sport || false
);

export const getFeatureToggleTimeline = createSelector(settingsSelector, (settings) => settings?.featTimeline || false);

export const getFeatureToggleCBDeleteNSave = createSelector(
  settingsSelector,
  (settings) => settings?.featCBDeleteNSave || false
);

export const getFeatSocialNetworksFooter = createSelector(
  settingsSelector,
  (settings) => settings?.featSocialNetworksFooter || false
);

export const getFeatCreativeMediaRC = createSelector(
  settingsSelector,
  (settings) => settings?.featCreativeMediaRC || false
);

export const footerDescriptionTitleSelector = createSelector(
  settingsSelector,
  (settings) => settings?.footerDescriptionTitle
);

export const footerDescriptionSelector = createSelector(settingsSelector, (settings) => settings?.footerDescription);

export const getFeatureExternalServicesSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featExternalServices || false
);

export const getFeatureToggleLocaleSwitcherSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featLocaleSwitcher || false
);

export const getFeatureToggleIsLowDataSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featLowData || false
);

export const getFeatureToggleDisplayPersoReco = createSelector(
  settingsSelector,
  (settings) => settings?.featDisplayPersoRecoToggle
);

// Must return true for now, it will be removed in another issue
export const isDarkModeSelector = (): boolean => true;

export const getFeatureToggleMultiLiveSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featMultiLive || false
);

export const getFeatureToggleMacOsAppAvailableSelector = createSelector(
  settingsSelector,
  (settings) => settings?.featMacOsAppAvailable || false
);

export const getFeatureToggleLiveTvFavorites = createSelector(
  settingsSelector,
  (settings) => settings?.featLiveTvFavorites || false
);

/**
 * Special feature toggle manage by hodor configurationJson
 */
export const getFeatureToggleBlueTim = createSelector(settingsSelector, (settings) => settings?.featTimBlue || false);

export const getFeatureToggleWhoIsWatching = createSelector(
  settingsSelector,
  (settings) => settings?.featWhoIsWatching || false
);

/**
 * Special feature toggle manage by hodor init
 */

export const getFeatureToggleClientPerfMeasures = createSelector(
  settingsSelector,
  (settings) => settings?.featClientPerfMeasures || false
);

export const getPlaybackHelpUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.playbackHelpUrl || ''
);

export const getAccountUrlSelector = createSelector(settingsSelector, (settings) => settings?.accountUrlDefault);

export const getEditAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathEditAccount
);

export const getEditPasswordAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathEditPassword
);

export const getCancellationAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathCancellation
);

export const getModificationAccountUrlSelector = createSelector(
  settingsSelector,
  (settings) => settings?.accountUrlPathModification
);

export const getAccountPathsSelector = createSelector(
  [
    getEditAccountUrlSelector,
    getEditPasswordAccountUrlSelector,
    getCancellationAccountUrlSelector,
    getModificationAccountUrlSelector,
  ],
  (editAccountUrl, editPasswordUrl, accountCancellationUrl, accountModificationUrl) => ({
    editAccountUrl,
    editPasswordUrl,
    accountCancellationUrl,
    accountModificationUrl,
  })
);

export const blackListedChannelsSelector = createSelector(settingsSelector, (settings) =>
  (settings?.blackListedChannels || '').split(',').map(Number)
);

export const getDarkLogoURLSelector = createSelector(
  settingsSelector,
  (settings) => settings?.logoForDarkModeUrl || ''
);

export const getLightLogoURLSelector = createSelector(
  settingsSelector,
  (settings) => settings?.logoForLightModeUrl || ''
);

export const galaxySelector = createSelector(applicationSelector, (application) => application?.galaxy);

export const logoSelector = createSelector(applicationSelector, (application) => application?.logo || {});

export const userMenuExtendedSelector = createSelector(
  applicationSelector,
  (application) => application?.userMenuExtended || ({} as ApiV2PageSectionsList)
);
export const userMenuLanguagesSelector = createSelector(
  applicationSelector,
  (application) => application?.userMenuLanguages
);

export const userMenuExtendedStratesSelector = createSelector(
  userMenuExtendedSelector,
  (userMenuExtended) => userMenuExtended.strates || []
);

export const userMenuExtendedNonProfileStratesSelector = createSelector(
  userMenuExtendedStratesSelector,
  (strates) => strates.filter((strate) => strate.type !== ContentType.Profiles) as (ISectionsStrate | IButtonStrate)[]
);

export const firstLevelPageListSelector = createSelector(
  applicationSelector,
  (application) => application?.firstLevelPageList || []
);

export const headerLevelPageListSelector = createSelector(
  applicationSelector,
  (application) => application?.headerLevelPageList || []
);

const pagePathnameSelector = (state: IState): string => {
  return state.page?.mainOnClick?.path || '';
};

export const isFirstLevelPageSelector = createSelector(
  [firstLevelPageListSelector, offerLocationSelector, pagePathnameSelector],
  (firstLevelPageList, offerLocation, path: string) => isFirstLevelPage({ firstLevelPageList, offerLocation, path })
);

export const getNBOLiveInformations = createSelector(settingsSelector, (settings) => ({
  NBOlivePlayerButtonLabel: settings.NBOlivePlayerButtonLabel,
  NBOlivePlayerButtonUrl: settings.NBOlivePlayerButtonUrl,
  NBOlivePlayerText: settings.NBOlivePlayerText,
}));

export const getThemeSelector = createSelector(
  settingsSelector,
  (settings) => settings?.theme || OVERLAY_APPLICATION_THEMES[getPublicConfig().overlay]
);

export const getD2gQualitySetting = createSelector(
  settingsSelector,
  (settings) => settings?.settingQualityD2G || SettingQualityD2G.default
);

export const getShortVideoIdsSelector = createSelector(
  applicationSelector,
  (application) => application?.shortVideoIds || []
);

export const kidsHomeUrlSelector = createSelector(settingsSelector, (settings): string => settings?.kidsHomeUrl || '');

export const featDisplayPersoRecoToggleSelector = createSelector(
  settingsSelector,
  (settings): boolean => !!settings?.featDisplayPersoRecoToggle
);

export const sharingURLBuilderSelector = createSelector([pathnameSelector, hostnameSelector], (pathname, hostname) =>
  pathname ? `https://${hostname}${pathname}` : ''
);

export const applicationResizeSelector = createSelector(
  applicationSelector,
  (stateApplication) => stateApplication?.resize
);

export const getStartupNotificationsSelector = createSelector(
  applicationSelector,
  (application): ApiV2NavigationNotification[] | undefined => {
    return application?.startupNotifications;
  }
);

export const slideshowNotificationsTrackingContextSelector = createSelector(
  applicationSelector,
  (application) => application?.startupNotificationsTrackingContext
);

export const slideshowNotificationsTrackingSelector = createSelector(
  applicationSelector,
  (application) => application?.startupNotificationsTracking
);

export const getWhoIsWatchingUserSettingsSelector = createSelector(applicationSelector, (application) => {
  return application?.whoIsWatchingUserSettings;
});

export const initTrackingSelector = createSelector(
  applicationSelector,
  (application): ApiV2InitDataLayer | undefined => {
    return application?.initTracking?.dataLayer;
  }
);

export const shouldDisplayWhoIsWatchingSelector = createSelector(
  [applicationSelector, userSelector],
  (application, user) => {
    return (
      user.authenticated &&
      user.reco &&
      !application.whoIsWatchingUserSettings.inSession &&
      !application.whoIsWatchingUserSettings.disabled
    );
  }
);
