import { getCookie, setCookie } from '@canalplus/mycanal-commons';
import { getSessionIdCookieValue } from '@canalplus/sdk-pass';
import { CookieKey } from '../../constants/cookie';

export const setSessionIdCookie = (): void => {
  const currentSessionId = getCookie(CookieKey.SessionId);

  if (!currentSessionId) {
    setCookie(CookieKey.SessionId, getSessionIdCookieValue(), {
      expires: 1 / 48, // 30 minutes
    });
  }
};
