import { isSomeEnum } from '@canalplus/mycanal-commons';
import {
  Lang,
  OfferLocation,
  OfferZone,
  Operator,
  Platform,
  getOperatorFromOfferZone,
  getZoneParameters,
  isOfferLocation,
} from '@canalplus/sdk-core';
import type { Request } from 'express';
import { AppKey, IConfig, IPublic } from '../../../config/application/types';
import { Queries } from '../../constants/url';

export const loadCSRConfig = async (): Promise<void> => {
  const query = new URLSearchParams(window.location.search);
  const platformQuery = query.get(Queries.Platform);
  const platform = platformQuery && isSomeEnum(Platform)(platformQuery) ? platformQuery : undefined;

  const offerZoneQuery = query.get(Queries.OfferZone);
  const offerZone = isSomeEnum(OfferZone)(offerZoneQuery) ? offerZoneQuery : undefined;

  if (!offerZone) {
    throw new Error(`Invalid offerZone provided in URL "${offerZone}"`);
  }

  // We disable several lint / ts checks in the lines below since built configuration may
  // not be available when linting (eg. in CI)
  /* eslint-disable import/no-unresolved */
  if (getOperatorFromOfferZone(offerZone) === Operator.Tim) {
    // @ts-expect-error config may not be built
    (window as any).app_config = await import('../../../config/transpiled/static-tim.json');
  } else {
    switch (platform) {
      case Platform.OrangeManhattan:
      case Platform.OrangeMib4:
      case Platform.OrangePlay:
      case Platform.OrangeStellar:
        // @ts-expect-error config may not be built
        (window as any).app_config = await import('../../../config/transpiled/static-orangebox.json');
        break;
      default:
        // @ts-expect-error config may not be built
        (window as any).app_config = await import('../../../config/transpiled/static-mycanal.json');
        break;
    }
  }
  /* eslint-enable */
};

/* eslint-disable global-require */

/** Return the whole public configuration */
export const getPublicConfig = (): IPublic => {
  // On server-side, retrieving the client config from the node config
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config').get('public');
  }

  // On client-side, the config is already loaded in window
  return (window as any).app_config;
};

/** Return the whole server configuration */
export const getServerConfig = (): IConfig => {
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config');
  }

  throw new Error('Server config is only available in server execution context.');
};

/* eslint-enable */

type Locale = {
  appKey?: AppKey;
  lang?: Lang;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  portailId?: string;
};

/**
 * Get the locale data from the path and the query
 * `isTvDevice`: boolean
 * `path`: string
 * `offerLocationQuery: string | null
 * @returns Locale
 */
export const getLocaleData = (
  isTvDevice: boolean,
  path: string,
  offerLocationQuery?: Request['query'] | string | null,
  offerZoneQuery?: Request['query'] | string | null
): Locale => {
  const {
    api: {
      pass: { operator },
    },
    defaultLocale,
    zones,
  } = getPublicConfig();

  const [, splittedOfferLocation] = path.split('/');

  const offerLocationFromPath = isOfferLocation(splittedOfferLocation)
    ? splittedOfferLocation
    : defaultLocale.offerLocation;

  const offerLocationTv =
    offerLocationQuery && isOfferLocation(offerLocationQuery) ? offerLocationQuery : defaultLocale.offerLocation;

  const { offerLocation, lang, portailId, offerZone, appKey } = getZoneParameters({
    offerLocation: isTvDevice ? offerLocationTv : offerLocationFromPath,
    // Temporary dev for german-speaking switzerland (waiting for the multi-language)
    lang: path.includes('/ch/de') || offerZoneQuery === OfferZone.cpchd ? 'de' : defaultLocale.langKey,
    zones,
    operator,
  });

  return { offerLocation, lang, portailId, offerZone, appKey };
};
