import { getPublicConfig } from '../config/config-helper';

type RecursiveValues = string | object[] | object;

/**
 * Replace all occurrences of an url in a string
 * @param string to format
 * @param fromList is a list of from endpoint to replace
 * @param toList is a list endpoints to replace by
 * @returns the string with all occurences of fromList item replaced by toList item
 */
const getProxifiedString = (string: string, fromList: string[], toList: string[]): string => {
  const index = fromList.findIndex((from) => string.includes(from));
  if (index !== -1) {
    return string.replace(fromList[index], toList[index]);
  }
  return string;
};

/**
 * Replace all occurrences of an url in an array
 * @param array to format
 * @param fromList is a list of from endpoint to replace
 * @param toList is a list endpoints to replace by
 * @returns the array with all occurences of fromList item replaced by toList item
 */
const getProxifiedArray = (array: object[], fromList: string[], toList: string[]): object[] => {
  return array.map((item) => rewriteUrl(item, fromList, toList)) as object[];
};

/**
 * Replace all occurences recursively of an url in an object
 * @param object to format
 * @param fromList is a list of from endpoint to replace
 * @param toList is a list endpoints to replace by
 * @returns the object with all occurences of fromList item replaced by toList item
 */
const rewriteUrl = (object: RecursiveValues, fromList: string[], toList: string[]): RecursiveValues => {
  if (typeof object === 'string') {
    return getProxifiedString(object, fromList, toList);
  }

  // if it's an array we map the array
  if (Array.isArray(object)) {
    return getProxifiedArray(object, fromList, toList);
  }

  // if it's an object we reduce the object
  if (typeof object === 'object') {
    return Object.keys(object).reduce((acc, key) => {
      const value = object[key];
      return {
        ...acc,
        [key]: rewriteUrl(value, fromList, toList),
      };
    }, {});
  }

  // if it's not a string, an array or an object we return it
  return object;
};

/**
 * Replace all occurences recursively of an url in an object
 * @param obj to format
 * @returns the object with all occurences of fromList item replaced by toList item
 */
export function rewriteObjectUrls<T extends RecursiveValues>(objectToFormat: T): T {
  const publicConfig = getPublicConfig();
  const proxificationList = publicConfig.proxificationList;

  if (!proxificationList) {
    return objectToFormat;
  }

  const fromList = proxificationList?.map((proxi) => proxi.from) || [];
  const toList = proxificationList?.map((proxi) => proxi.to) || [];

  // used to avoid to call proxiUrl if there is nothing to replace
  if (fromList.length === 0 || toList.length === 0) {
    return objectToFormat;
  }

  return rewriteUrl(objectToFormat, fromList, toList) as T;
}
