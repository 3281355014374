import type { IUserInformation } from '@canalplus/mycanal-sdk';
import { type CreateTokenParameters, type PassUserDataEnriched, createToken } from '@canalplus/sdk-pass';
import type { IState } from '../../store/types/State-type';
import { setDeviceIdCookie } from '../cookie/setDeviceIdCookie';
import { setSessionIdCookie } from '../cookie/setSessionIdCookie';
import { getPassId } from '../pass/getPassId';
import { getPassSdkConfig } from '../pass/getPassSdkConfig';
import { setPassJSON } from '../pass/setPassJSON';
import { refreshProfileOneCore } from '../r7/r7-helpers';
import { isR7Available } from '../window/window-helper';

/**
 * - On TV, retrieves the user information from the R7 API by calling `refreshProfileOneCore`
 * - On browser, performs a full **Pass createToken** cinematic
 *   - Calls `createToken` and returns the enriched user data
 *   - Sets user Data in **window.passJSON**
 *   - Sets the session ID cookie if needed
 *   - Sets the device ID cookie if needed
 */
export const getClientSideUserInfos = async (
  state: IState,
  createTokenParameters?: Omit<CreateTokenParameters, 'passId' | 'passIdType'>
): Promise<IUserInformation | PassUserDataEnriched> => {
  if (isR7Available()) {
    return refreshProfileOneCore();
  }

  const { userData, passId, passToken } = await createToken(getPassSdkConfig({ state }), {
    ...createTokenParameters,
    passId: getPassId(),
    passIdType: 'pass',
  });

  setPassJSON({
    ...userData,
    passToken,
  });

  setSessionIdCookie();
  setDeviceIdCookie(userData);

  return {
    ...userData,
    passToken,
    passId,
  };
};
